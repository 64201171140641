import anime from 'animejs';

export default {
  reset($el, readyFn) {
    this.$background = $el.querySelector('.background');
    this.$content = $el.querySelector('.content');
    this.$breadCrumb = $el.querySelector('.bread-crumb');
    this.$prefix = $el.querySelector('.prefix');
    this.$text = $el.querySelector('.text');

    anime
      .timeline({
        duration: 0,
        complete: () => {
          $el.removeAttribute('data-anime');
          readyFn();
        },
      })
      .add({
        targets: this.$background,
        opacity: 0,
        scale: 1.1,
      })
      .add({
        targets: this.$content,
        translateY: '100%',
      })
      .add({
        targets: this.$breadCrumb,
        opacity: 0,
      })
      .add({
        targets: this.$prefix,
        opacity: 0,
      })
      .add({
        targets: this.$text,
        opacity: 0,
      });

    return this;
  },
  play($el) {
    return this.reset($el, () => {
      anime
        .timeline()
        .add({
          targets: this.$background,
          scale: 1,
          opacity: 1,
          duration: 1000,
          easing: 'easeInOutQuint',
          delay: 50,
        })
        .add({
          targets: this.$content,
          translateY: '0%',
          duration: 1000,
          delay: 500,
          easing: 'easeInOutQuint',
        }, '-=1000')
        .add({
          targets: this.$breadCrumb,
          opacity: 1,
          duration: 800,
          easing: 'easeInOutQuint',
        }, '-=600')
        .add({
          targets: this.$prefix,
          opacity: 1,
          duration: 800,
          easing: 'easeInOutQuint',
        }, '-=600')
        .add({
          targets: this.$text,
          opacity: 1,
          duration: 800,
          easing: 'easeInOutQuint',
        }, '-=600');
    });
  },
};

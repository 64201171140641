import BaseComponent from './baseComponent';

/**
 * Fixes the location of anchor elements that would normally lie behind the header
 * with position: fixed. This component repositions the scrollY value based on whether
 * the current window.location contains a valid hash.
 */
export default class AnchorLocationFixer extends BaseComponent {
  /**
     * Public constructor
     *
     * @param options
     */
  constructor(options) {
    super(options);

    this.offsetValue = options.offsetValue || 0;

    // The default "valid" location has consists of numbers only, e.g. #123
    this.locationHashValidRegex = options.locationHashValidRegex || /#\d+/;

    return this;
  }

  /**
     * Scrolls the window to the current hash position + the offset
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
     */
  init() {
    if (this.isValidLocationHash() && this.offsetValue !== 0) {
      // Even react does this, so we should be ok
      // @see https://github.com/facebook/react/blob/5d3b12bb3bd6a092cf00ede07b8255a8399c2e58/src/vendor/core/dom/getUnboundedScrollPosition.js#L28
      const x = window.pageXOffset || document.documentElement.scrollLeft;
      const y = window.pageYOffset || document.documentElement.scrollTop;
      const offsetY = y + this.offsetValue;

      window.scrollTo(x, offsetY);
    }
  }

  /**
     * Checks whether the current browser location contains a hash
     * and whether the location has is valid (validates through the regex option).
     *
     * @return {boolean}
     */
  isValidLocationHash() {
    if (!window.location.hash) {
      return false;
    }

    const { hash } = window.location;
    if (!hash.match(this.locationHashValidRegex)) {
      return false;
    }

    return true;
  }
}

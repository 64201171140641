import $ from 'jquery';
import BaseComponent from './baseComponent';

/**
 * Handler for filling forms.
 */
export default class PollFiller extends BaseComponent {
  /**
     * @param options
     */
  constructor(options) {
    super(options);
    this.$forms = $('[data-poll-form]');
    this.localStoragePollId = 'vp-bank-poll-results';
    this.classLoading = 'indicator-loading';
  }

  init() {
    if (this.$forms.length < 1) {
      return;
    }

    this.loadExistingPolls();
    this.initForms();
  }

  /**
     * Setup the form-handler.
     */
  initForms() {
    this.$forms.on('submit', (event) => {
      event.preventDefault();

      const form = event.target;
      const $submit = $(form).find('button');
      const url = form.action;
      const query = $(form).serialize();

      if (!query) {
        return;
      }

      const urlQuery = `${url}?${query}`;

      $submit.addClass(this.classLoading);

      $.ajax(
        urlQuery,
        {},
      ).fail(() => {
        $submit.removeClass(this.classLoading);
      }).done((data) => {
        const paragraphId = Object.keys(data)[0];
        const results = data[paragraphId];
        this.setPollResults(paragraphId, results);

        $submit.removeClass(this.classLoading);

        const existingLocalPollResults = JSON.parse(
          this.getKeyValue(this.localStoragePollId),
        ) || [];

        if ($.inArray(paragraphId, existingLocalPollResults) < 0) {
          existingLocalPollResults.push(paragraphId);
        }

        this.setKeyValue(this.localStoragePollId, JSON.stringify(existingLocalPollResults));
      });
    });
  }

  /**
     * Set Poll results. Fill in results, hide form, show results.
     *
     * @param id
     * @param results
     */
  setPollResults(id, results) {
    const $poll = $(`[data-poll="${id}"]`);
    const $result = $poll.find('[data-result]');

    if ($result.length < 1) {
      return;
    }

    let { total } = results;
    const $answers = {};

    $result.html($result.html().replace('%total', total));

    $poll.removeClass('-hideform').removeClass(this.classLoading).addClass('-showresult');

    // First: remove old / deleted answers from total...
    $.each(results, (index, element) => {
      if (index !== 'total') {
        const $answer = $(`[data-result-id="${index}"]`);
        if ($answer.length < 1) {
          total -= element;
        } else {
          $answers[index] = $answer;
        }
      }
    });

    $.each(results, (index, element) => {
      if ($answers[index]) {
        const $answer = $answers[index];
        const percent = Math.round((100 / total) * element);
        $answer.find('[data-bar]').width(`${percent}%`);
        $answer.find('[data-percent]').html(percent);
      }
    });

    $result.html($result.html().replace(results.total, total));
  }

  loadExistingPolls() {
    const existingPolls = JSON.parse(this.getKeyValue(this.localStoragePollId));

    if (!existingPolls) {
      return;
    }

    $.each(existingPolls, (id, element) => {
      const $poll = $(`[data-poll="${element}"]`);
      $poll.addClass('-hideform').addClass(this.classLoading);
      const url = $poll.find('form').attr('action');

      $.ajax(
        url,
        {},
      ).fail(() => {
      }).done((data) => {
        const paragraphId = Object.keys(data)[0];
        const results = data[paragraphId];
        this.setPollResults(paragraphId, results);
      });
    });
  }

  /**
     * Helper: Get a value based on a key:
     *
     * @param key
     * @returns {string | null}
     */
  getKeyValue(key) {
    if (!this.hasLocalStorage()) {
      return null;
    }

    return window.localStorage.getItem(key);
  }

  /**
     * Helper: Save a key-value-combo
     *
     * @param key
     * @param value
     */
  setKeyValue(key, value) {
    if (this.hasLocalStorage()) {
      window.localStorage.setItem(key, value);
    }
  }

  /**
     * Helper: Check if we have a localStorage.
     *
     * @returns {boolean}
     */
  hasLocalStorage() { // eslint-disable-line class-methods-use-this
    return typeof window.localStorage !== 'undefined';
  }
}

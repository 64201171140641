import anime from 'animejs';

export default {
  reset($el, readyFn) {
    this.$image = $el.querySelector('.imagecontainer');
    this.$form = $el.querySelector('.formwrapper');
    this.$prefix = $el.querySelector('.prefix');
    this.$title = $el.querySelector('.text');
    this.$actions = $el.querySelector('.actions');
    this.translateXReset = '-100%';

    if ($el.classList.contains('-reversed')) {
      this.translateXReset = '100%';
    }

    anime
      .timeline({
        duration: 0,
        complete: () => {
          $el.removeAttribute('data-anime');
          readyFn();
        },
      })
      .add({
        targets: this.$image,
        translateX: this.translateXReset,
      })
      .add({
        targets: this.$form,
        translateX: this.translateXReset,
      })
      .add({
        targets: this.$prefix,
        translateY: '30px',
        opacity: 0,
      })
      .add({
        targets: this.$title,
        translateY: '30px',
        opacity: 0,
      })
      .add({
        targets: this.$actions,
        translateY: '30px',
        opacity: 0,
      });

    return this;
  },
  play($el) {
    return this.reset($el, () => {
      const timeline = anime.timeline({
        easing: 'easeInOutExpo',
      });

      timeline
        .add({
          targets: this.$prefix,
          opacity: 1,
          translateY: 0,
          duration: 800,
          delay: 50,
          easing: 'easeOutSine',
        })
        .add({
          targets: this.$title,
          opacity: 1,
          translateY: 0,
          duration: 800,
          easing: 'easeOutSine',
        }, '-=600')
        .add({
          targets: this.$actions,
          opacity: 1,
          translateY: 0,
          duration: 800,
          easing: 'easeOutSine',
        }, '-=600')
        .add({
          targets: this.$image,
          translateX: 0,
          duration: 2500,
        }, '-=1500')
        .add({
          targets: this.$form,
          translateX: 0,
          duration: 2500,
        }, '-=1500');
    });
  },
};

const defaults = {
  moduleSelector: '[data-footer]',
};

export default class Footer {
  constructor(settings = {}) {
    this.settings = { ...defaults, ...settings };
  }

  init() {
    const $modules = [...document.querySelectorAll(this.settings.moduleSelector)];

    if ($modules.length === 0) {
      return this;
    }

    $modules.forEach((module) => {
      const $countryToggle = module.querySelector('[data-footer-toggle]');

      if (!$countryToggle) {
        return;
      }

      document.addEventListener('click', (event) => {
        if (event.target === $countryToggle) {
          $countryToggle.parentNode.classList.toggle('-open');
        } else {
          $countryToggle.parentNode.classList.remove('-open');
        }
      });
    });

    return this;
  }
}

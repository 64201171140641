import $ from 'jquery';
import BaseComponent from './baseComponent';

/**
 * Component for inserting svgs into html
 */
export default class SvgReplacer extends BaseComponent {
  /**
     * Public constructor
     *
     * @param options
     */
  constructor(options) {
    super(options);
    this.svgClass = options.svgClass || null;
    this.$svgs = $(`.${this.svgClass}`);

    return this;
  }

    addInlineSvgs($elements) { // eslint-disable-line
    let count = 0;

    $.each($elements, (index, svg) => {
      const $img = $(svg);
      let imgID = $img.attr('id');
      const imgAlt = $img.attr('alt');
      const imgURL = $img.attr('src');
      const imgStyle = $img.attr('style');
      let imgClass = $img.attr('class');
      let randomNumber = 10;

      $.get(imgURL, (data) => {
        // Get the SVG tag, ignore the rest
        let $svg = $(data).find('svg');

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== 'undefined') {
          // ...
        } else {
          randomNumber = Math.floor(Math.random() * (999999 - 10 + 1) + 10);
          imgID = `random-id-${randomNumber}`;
        }
        $svg = $svg.attr('id', imgID);

        if (typeof imgClass === 'undefined') {
          imgClass = '';
        }

        if (typeof imgStyle !== 'undefined') {
          $svg = $svg.attr('style', imgStyle);
        }

        // Add replaced alt-text.
        if (typeof imgAlt !== 'undefined') {
          $svg = $svg.attr('alt', imgAlt);
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Scope styles
        const $style = $svg.find('style');

        if ($style.length > 0) {
          let styles = $style[0].innerHTML;
          const regex = /\.(?![0-9])/g; // Find dots not followed by a number

          styles = styles.replace(regex, `#${imgID} .`);
          $style[0].innerHTML = styles;
        }

        // Replace image with new SVG
        $img.replaceWith(`<div class="${this.svgClass} ${this.svgClass}-${count} ${imgClass} ${this.svgClass}--replaced" data-image-src-url="${imgURL}"></div>`);
        $(`.${this.svgClass}-${count}`).html($svg);

        // Set Correct size.
        this.setSizeOfInlineSvg(`${this.svgClass}-${count}`);

        count += 1;
      }, 'xml');
    });
  }

  setSizeOfInlineSvg(elementClass) { // eslint-disable-line class-methods-use-this
    const $inlineSvg = $(`.${elementClass}`).find('svg');
    const inlineSvg = $inlineSvg[0];
    const viewBox = inlineSvg.getAttribute('viewBox');

    if (viewBox && !inlineSvg.getAttribute('width')) {
      const width = viewBox.split(' ')[2];
      inlineSvg.setAttribute('width', `${width}px`);
    }

    if (viewBox && !inlineSvg.getAttribute('height')) {
      const height = viewBox.split(' ')[3];
      inlineSvg.setAttribute('height', `${height}px`);
    }
  }

  init() {
    if (this.$svgs.length < 1) {
      return;
    }

    this.addInlineSvgs(this.$svgs);
  }
}

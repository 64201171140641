import $ from 'jquery';
import BaseComponent from './baseComponent';

/**
 * Component that implements the main navigation functionality.
 */
export default class NavigationToggler extends BaseComponent {
  /**
     * Public constructor
     *
     * @param options
     */
  constructor(options) {
    super(options);

    this.targetToggleClass = options.targetToggleClass || '-visible';
    this.handlerToggleClass = options.handlerToggleClass || '-open';
    this.handlerSelector = '> [data-handler]';
    this.handlerTargetSelector = options.handlerTargetSelector || '+ [data-target]';
    this.onTargetShow = options.onTargetShow || null; // show callback
    this.onTargetHide = options.onTargetHide || null; // hide callback

    this.$handlers = this.$item.find(this.handlerSelector);
    this.$lastVisibleTarget = null;

    return this;
  }

  /**
     * Initialize function
     */
  init() {
    if (this.$handlers.length > 0) {
      this.$handlers.each((index, item) => {
        const $handler = $(item);
        const $target = $handler.find(this.handlerTargetSelector);

        $handler.on('click', (e) => {
          const $eventTarget = $(e.target);

          // The handler element might have further children whose events will also
          // be caught in this `click` handler (because they bubble up the DOM), we
          // only want to react on clicks when the handler directly is clicked or
          // its immediate child is clicked. We ignore all other events.
          //
          // IMPORTANT NOTE: This means children of a handler's child won't do anything.
          if (!$eventTarget.is($handler) && !$eventTarget.parent().is($handler)) {
            return;
          }

          // If handler has an existing target
          if ($target.length === 1) {
            // Only prevent default when navigation items have children
            // When they don't just proceed with their "normal" click action
            e.preventDefault();

            this.hideAllTargets();

            if (this.$lastVisibleTarget === $target) {
              this.hideTarget($handler, $target);
              this.$lastVisibleTarget = null;
            } else {
              this.showTarget($handler, $target);
              this.$lastVisibleTarget = $target;
            }

            return;
          }

          this.hideAllTargets();
          this.$lastVisibleTarget = null;
        });
      });

      // Hide all targets if a `click` happens outside of our item
      $(document).on('click', (e) => {
        if (!$.contains(this.$item.get(0), e.target)) {
          this.hideAllTargets();
          this.$lastVisibleTarget = null;
        }
      });
    }
  }

  /**
     * Displays a target
     *
     * @param $handler Target handler jQuery element
     * @param $target Target jQuery element
     */
  showTarget($handler, $target) {
    if ($target.length) {
      $target.addClass(this.targetToggleClass);
      $handler.addClass(this.handlerToggleClass);

      if (typeof this.onTargetShow === 'function') {
        this.onTargetShow.call(this, $target);
      }
    }
  }

  /**
     * Hides a target
     *
     * @param $handler Target handler jQuery element
     * @param $target Target jQuery element
     */
  hideTarget($handler, $target) {
    if ($target.length) {
      $target.removeClass(this.targetToggleClass);
      $handler.removeClass(this.handlerToggleClass);

      if (typeof this.onTargetHide === 'function') {
        this.onTargetHide.call(this, $target);
      }
    }
  }

  /**
     * Hides all targets
     *
     * @param $handler Target handler jQuery element
     */
  hideAllTargets() {
    if (this.$handlers.length > 0) {
      this.$handlers.each((index, item) => {
        const $handler = $(item);
        const $target = $handler.find(this.handlerTargetSelector);

        this.hideTarget($handler, $target);
      });
    }
  }
}

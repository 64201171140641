import $ from 'jquery';
import holmes from 'holmes.js';
import BaseComponent from './baseComponent';

/**
 * Search through content
 */
export default class SearchContent extends BaseComponent {
  /**
     * Public constructor
     *
     * @param options
     */
  constructor(options) {
    super(options);

    this.identifierBox = '[data-search-content]';
    this.identifierInput = '[data-search-content-input]';
    this.identifierFind = 'searchContentFind';
    this.identifierClassHidden = 'searchContentClassHidden';

    return this;
  }

  /**
     * Initialize function
     */
  init() {
    const $elements = $(this.identifierBox);

    $.each($elements, (index, element) => {
      const $element = $(element);

      // prepare find-selector-class
      $element.addClass(`search-content-box-${index}`);

      // prepare input-selector-class
      $element.find(this.identifierInput).addClass(`search-content-box-${index}-input`);

      const input = `.search-content-box-${index} .search-content-box-${index}-input`;
      const find = `.search-content-box-${index} ${$element.data(this.identifierFind)}`;
      const hiddenClass = $element.data(this.identifierClassHidden);
      const options = {
        input,
        find,
        mark: true,
        dynamic: true,
        placeholder: false,
        class: {
          hidden: hiddenClass,
        },
      };

            const h = holmes(options); // eslint-disable-line
      h.start();
    });
  }
}

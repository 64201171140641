import Vivus from 'vivus';

export default class {
  init() {
    const $els = document.querySelectorAll('[data-vivus]');

    const getOptions = ($el) => ({
      duration: 100,
      onReady: () => $el.classList.add('-ready'),
      ...JSON.parse($el.dataset.vivus || '{}'),
    });

    [...$els].forEach(
      ($el) => (new Vivus($el, getOptions($el))),
    );

    return this;
  }
}

const defaults = {
  moduleSelector: '[data-scroll-to-the-top]',
};

export default class ScrollToTheTop {
  constructor(settings = {}) {
    this.settings = { ...defaults, ...settings };
  }

  init() {
    const $modules = [...document.querySelectorAll(this.settings.moduleSelector)];

    if ($modules.length === 0) {
      return this;
    }

    $modules.forEach((module) => {
      module.addEventListener('click', () => {
        const rootElement = document.documentElement;

        rootElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
    });

    return this;
  }
}

export default class Header {
  init() {
    this.scrollPosition = 0;

    this.hasGoalBasedAdvisoryIntro = document.querySelector('[data-goal-based-advisory-intro]');

    this.header = document.querySelector('.page-header');
    this.headerHeight = this.header?.getBoundingClientRect().height;
    this.navContainer = this.header?.querySelector('.nav-container');
    this.navBackContainer = this.navContainer?.querySelector('.back-container');
    this.ctaNews = this.navContainer?.querySelector('.cta-news');

    const searchContainer = this.navContainer?.querySelector('.search-container');
    this.searchOverlay = searchContainer?.querySelector('.overlay');
    this.searchInput = this.searchOverlay?.querySelector('.input');

    this.navMeta = this.navContainer?.querySelector('.nav-meta.-items');
    this.navMetaToggles = this.navContainer?.querySelector('.nav-meta.-toggles');
    this.navMetaSecond = this.navContainer?.querySelector('.nav-meta-second');
    this.navMetaWrapper = this.navMetaSecond?.querySelector('.wrapper');
    this.navMetaItems = this.navMeta ? [...this.navMeta.querySelectorAll('.item')] : [];
    this.navMetaItemsToggles = this.navMetaToggles ? [...this.navMetaToggles.querySelector('.list').querySelectorAll('.item')] : [];
    this.navMetaItemsSecond = this.navMetaSecond ? [...this.navMetaSecond.querySelector('.list').querySelectorAll('.item')] : [];

    this.navMainFirst = this.navContainer?.querySelector('.nav-main');
    this.navMainSecond = this.navContainer?.querySelector('.nav-main-second');
    this.navMainSecondWrapper = this.navMainSecond?.querySelector('.wrapper');
    this.navMainItemsLinks = this.navMainFirst ? [...this.navMainFirst.querySelectorAll('.item.-children .link')] : [];
    this.navMainItemsFirst = this.navMainFirst ? [...this.navMainFirst.querySelectorAll('.item.-children')] : [];
    this.navMainItemsSecond = this.navContainer && this.navContainer.querySelector('.nav-main-second') ? [...this.navContainer.querySelector('.nav-main-second').querySelectorAll('.list')] : [];

    const buttonCloseNavMain = this.navMainSecond?.querySelector('.close');
    const buttonBackToFirstNav = this.navBackContainer?.querySelector('.back');
    const buttonClickBackCloseNavSecond = this.navBackContainer?.querySelector('.close');
    const buttonSearch = searchContainer?.querySelector('.search');
    this.buttonCloseSearchOverlay = this.searchOverlay?.querySelector('.close');
    this.buttonHamburger = this.navContainer?.querySelector('.hamburgermenu');

    this.closeNavMainSecond = this.closeNavMainSecond.bind(this);
    this.closeNavMeta = this.closeNavMeta.bind(this);
    this.closeSearchContainer = this.closeSearchContainer.bind(this);
    this.showNavMainSecond = this.showNavMainSecond.bind(this);
    this.showNavMeta = this.showNavMeta.bind(this);
    this.showSearchContainer = this.showSearchContainer.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.onClickBackCloseNavSecond = this.onClickBackCloseNavSecond.bind(this);
    this.onClickHamburger = this.onClickHamburger.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onScroll = this.onScroll.bind(this);

    buttonCloseNavMain?.addEventListener('click', this.closeNavMainSecond);
    this.buttonCloseSearchOverlay?.addEventListener('click', this.closeSearchContainer);
    document.addEventListener('click', this.onClickOutside);
    buttonClickBackCloseNavSecond?.addEventListener('click', this.onClickBackCloseNavSecond);
    this.buttonHamburger?.addEventListener('click', this.onClickHamburger);
    buttonBackToFirstNav?.addEventListener('click', this.onClickBack);
    this.navMainItemsLinks.forEach((item) => item.addEventListener('click', this.showNavMainSecond));
    if (this.navMetaToggles?.querySelector('.-is-location')) {
      this.navMetaToggles?.querySelector('.-is-location').addEventListener('click', this.showNavMeta);
    }
    if (this.navMetaToggles?.querySelector('.-is-location .link')) {
      this.navMetaToggles?.querySelector('.-is-location .link').addEventListener('click', this.showNavMeta);
    }
    this.navMetaItems?.forEach((item) => item.addEventListener('click', this.showNavMeta));
    buttonSearch?.addEventListener('click', this.showSearchContainer);

    if (this.hasGoalBasedAdvisoryIntro) {
      this.unFix();
    }

    window.addEventListener('scroll', this.onScroll);

    this.mql = window.matchMedia('(min-width: 1200px)');
    try {
      this.mql.addEventListener('change', this.onResize);
    } catch (error) {
      this.mql.addListener(this.onResize);
    }
    this.onResize();

    this.onCalculateHeighestMainList();

    this.createTitleMainNav();
  }

  onResize() {
    this.closeNavMainSecond();
    this.closeNavMeta();

    this.isDesktop = this.mql.matches;
    this.isSmall = !this.isDesktop;

    if (this.navContainer) {
      if (this.isSmall) {
        this.navContainer.style.overflow = 'auto';
      } else {
        window.setTimeout(() => this.onCalculateHeighestMainList(), 0);
        this.navContainer.style.overflow = null;
      }
    }
  }

  onCalculateHeighestMainList() {
    if (this.isSmall) return;

    const heights = [];

    this.navMainSecond?.querySelectorAll('.list').forEach((el) => {
      el.classList.add('-is-active');
      const { height } = el?.getBoundingClientRect();
      heights.push(height);
      el.classList.remove('-is-active');
    });

    this.highest = Math.max(...heights);
  }

  createTitleMainNav() {
    this.mainNavSecondTitle = document.createElement('p');
    this.mainNavSecondTitle.classList.add('title');
    this.navMainSecondWrapper?.insertBefore(
      this.mainNavSecondTitle, this.navMainSecond?.querySelector('.close').nextSibling,
    );
  }

  onClickOutside(event) {
    if (this.isSmall) {
      this.navMetaToggles?.querySelector('.nav-meta-menu.-is-location').classList.remove('-is-active');
      return;
    }

    if (!event.target.closest('.page-header')) {
      this.closeNavMainSecond();
    }
    if (!event.target.closest('.meta-nav')) {
      this.closeNavMeta();
    }
  }

  showNavMainSecond(event) {
    const currentItem = event.target;

    this.navMainSecond?.classList.add('-is-active');
    this.navMainItemsSecond.forEach((el) => {
      el.classList[el.dataset.item === currentItem.dataset.item ? 'add' : 'remove']('-is-active');
    });

    if (this.isSmall) {
      this.navContainer.scrollTop = 0;
      this.navBackContainer?.classList.add('-is-active');
      this.navContainer?.classList.add('-is-show-main');

      this.navMainSecondWrapper?.classList.remove('-is-fade-out');
      this.mainNavSecondTitle.textContent = currentItem.title;

      setTimeout(() => {
        this.navMainSecondWrapper?.classList.add('-is-fade-in');
      }, 100);
    }

    if (this.isDesktop) {
      if (currentItem.closest('.item').classList.contains('-is-highlighted')) {
        this.closeAllMainNav();
      } else {
        this.navMainItemsFirst.forEach((el) => el.classList.remove('-is-highlighted'));
        currentItem.closest('.item').classList.add('-is-highlighted');
        this.ctaNews?.classList.add('-is-main-open');
        const paddingTop = window.getComputedStyle(this.navMainSecond, null).getPropertyValue('padding-top');
        const paddingBottom = window.getComputedStyle(this.navMainSecond, null).getPropertyValue('padding-bottom');
        this.navMainSecond.style.height = `calc(${this.highest}px + ${paddingTop} + ${paddingBottom})`;
      }
    }
  }

  closeNavMainSecond() {
    if (this.isDesktop) {
      this.closeAllMainNav();
    }

    if (this.isSmall) {
      this.navContainer?.classList.add('-is-closed');
      this.navContainer?.classList.remove('-is-show-main');
      this.navBackContainer?.classList.remove('-is-active');
      this.navMainSecondWrapper?.classList.remove('-is-fade-in');
      this.navMainSecond?.classList.remove('-is-active');
    }
  }

  onClickBackCloseNavSecond() {
    if (this.isDesktop) return;

    this.closeNavMainSecond();
    this.navMetaSecond?.classList.remove('-is-active');
    this.navMetaItemsSecond?.forEach((el) => el.classList.remove('-is-active'));
    this.navContainer?.classList.remove('-is-show-meta');
  }

  showNavMeta(event) {
    event.stopPropagation();

    const isLocation = event.target.closest('.-is-location');

    if (isLocation) {
      const menuOpen = this.navMetaToggles?.querySelector('.nav-meta-menu.-is-location').classList.contains('-is-active');
      this.closeAllMainNav();
      this.closeNavMeta();
      if (!menuOpen) {
        this.navMetaToggles?.querySelector('.nav-meta-menu.-is-location').classList.add('-is-active');
      }
    }
    if (this.isSmall && !isLocation) {
      this.closeNavMeta();
      this.navMetaSecond?.classList.add('-is-active');

      const currentItem = event.target.dataset.item;

      this.navMetaItemsSecond.forEach((el) => {
        el.classList[el.dataset.item === currentItem ? 'add' : 'remove']('-is-active');
      });

      this.navMetaWrapper.classList.remove('-is-fade-out');
      this.navContainer?.classList.add('-is-show-meta');
      this.navBackContainer?.classList.add('-is-active');

      setTimeout(() => {
        this.navMetaWrapper.classList.add('-is-fade-in');
      }, 100);
    }

    if (this.isDesktop && !isLocation) {
      const menuOpen = event.target.closest('.item').classList.contains('-is-highlighted');
      this.closeAllMainNav();
      this.closeNavMeta();
      if (!menuOpen) {
        event.target.closest('.item').classList.add('-is-highlighted');
        event.target.closest('.item').querySelector('.nav-meta-menu')?.classList.add('-is-active');
      }
    }
  }

  closeNavMeta() {
    if (this.navMetaToggles) {
      if (this.navMetaToggles.querySelector('.nav-meta-menu.-is-location')) {
        this.navMetaToggles.querySelector('.nav-meta-menu.-is-location').classList.remove('-is-active');
      }

      if (this.navMetaToggles.querySelector('.-is-location')) {
        this.navMetaToggles.querySelector('.-is-location').querySelector('.nav-meta-menu')?.classList.remove('-is-active');
      }
    }

    if (this.isSmall) {
      this.navContainer?.classList.remove('-is-show-meta');
      this.navMetaSecond?.classList.remove('-is-active');
    }

    if (this.isDesktop) {
      this.navMetaItems?.forEach((el) => el.querySelector('.nav-meta-menu')?.classList.remove('-is-active'));
    }
  }

  showSearchContainer(event) {
    event.stopPropagation();

    event.target.closest('.search-container').querySelector('.overlay').classList.add('-is-active');
    this.searchOverlay?.querySelector('.input').focus();

    if (this.isDesktop) {
      this.navMetaItems.forEach((el) => el.classList.add('-is-hidden'));
      this.closeAllMainNav();
    }
  }

  closeSearchContainer(event) {
    event.stopPropagation();

    this.searchInput.value = '';
    event.target.closest('.nav-container').querySelector('.search-container').querySelector('.overlay').classList.remove('-is-active');

    if (this.isDesktop) {
      this.navMetaItems.forEach((el) => el.classList.remove('-is-hidden'));
      if (this.navMetaItems) this.navMetaItems.forEach((el) => el.querySelector('.nav-meta-menu').classList.remove('-is-active'));
    }

    if (this.isSmall) {
      this.navMainFirst.classList.remove('-is-hidden');
      this.navMeta.classList.remove('-is-hidden');
      this.navMetaToggles.classList.remove('-is-hidden');
    }
  }

  onClickHamburger(event) {
    event.stopPropagation();

    if (this.isDesktop) return;

    this.buttonHamburger?.classList.toggle('-is-open');
    this.navContainer?.classList.toggle('-is-closed');
  }

  onClickBack(event) {
    event.stopPropagation();

    if (this.isDesktop) return;

    this.navMainSecondWrapper?.classList.add('-is-fade-out');
    this.navMetaSecond?.querySelector('.wrapper').classList.add('-is-fade-out');
    this.navMainSecondWrapper?.classList.remove('-is-fade-in');
    this.navMetaSecond?.querySelector('.wrapper').classList.remove('-is-fade-in');

    setTimeout(() => {
      this.navBackContainer?.classList.remove('-is-active');
      this.navMainSecond?.classList.remove('-is-active');
      this.navMetaSecond?.classList.remove('-is-active');
      this.navContainer?.classList.remove('-is-show-main', '-is-show-meta');
    }, 400);
  }

  onScroll() {
    if (this.isSmall) return;

    if (window.scrollY > this.scrollPosition && window.scrollY > this.headerHeight) {
      this.header?.classList.add('-is-hidden');
      this.closeAllNavSeconds();
    } else {
      this.header?.classList.remove('-is-hidden');
    }

    this.scrollPosition = window.scrollY;
  }

  closeAllNavSeconds() {
    if (this.isSmall) return;

    if (this.navMetaItems) this.navMetaItems.forEach((el) => el.querySelector('.nav-meta-menu')?.classList.remove('-is-active'));
    this.navMetaItemsToggles.forEach((el) => el.querySelector('.nav-meta-menu').classList.remove('-is-active'));

    this.closeAllMainNav();
  }

  closeAllMainNav() {
    if (this.isSmall) return;

    [...document.querySelectorAll('.page-header .item.-children')].forEach(($item) => {
      $item.classList.remove('-is-highlighted');
    });

    // this.navMainItemsFirst.forEach((el) => el.classList.remove('-is-highlighted'));

    if (this.navMainSecond) {
      this.navMainSecond?.classList.remove('-is-active');
      this.navMainSecond.style.height = 0;
    }

    this.navMainItemsSecond.forEach((el) => el.classList.remove('-is-active'));
    this.ctaNews?.classList.remove('-is-main-open');
  }

  unFix() {
    this.header.classList.add('-not-fixed');
  }
}

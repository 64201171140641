// Relative imports
import $ from 'jquery';

// Absolute imports
import BaseComponent from './baseComponent';

/**
 * Floating elements are elements which are floating on the website with the scrolling position.
 * For example on the news-page: the social-media-icons on the right side.
 */
export default class FloatingElement extends BaseComponent {
  /**
     * Public constructor
     *
     * @param options
     */
  constructor(options) {
    super(options);

    this.dataIdentifier = 'float';
    this.dataIdentifierOffsetAdmin = 'float-offset-admin';
    this.dataIdentifierOffsetMobile = 'float-offset-mobile';
    this.dataIdentifierOffsetDesktop = 'float-offset-desktop';
    this.floaters = {};
    this.doc = document.documentElement;

    const $floaters = $(`[data-${this.dataIdentifier}]`);

    $floaters.each((index, el) => {
      this.floaters[index] = {
        $element: $(el),
      };
    });

    return this;
  }

  /**
     * Set all sizes which depends on the screen-size.
     */
  setWindowBasedSizes() {
    this.windowHeight = $(window).height();
    this.windowWidth = $(window).width();
    this.floatStarts = {};
    this.floatStops = {};

    Object.keys(this.floaters).forEach((key) => {
      const floaterObject = this.floaters[key];

      // remove fix class first
      floaterObject.$element.removeClass(floaterObject.$element.data(this.dataIdentifier));

      let top = parseInt(floaterObject.$element.offset().top, 10);
      const height = parseInt(floaterObject.$element.outerHeight(), 10);
      const $parent = $(floaterObject.$element.parent());
      const parentTop = parseInt($parent.offset().top, 10);
      const parentHeight = parseInt($parent.outerHeight(), 10);
      const stop = (parentTop + parentHeight) - height;

      // Check for offsets
      if (floaterObject.$element.data(this.dataIdentifierOffsetAdmin) !== 'undefined' && this.isAdminPage()) {
        top -= floaterObject.$element.data(this.dataIdentifierOffsetAdmin);
      } else if (floaterObject.$element.data(this.dataIdentifierOffsetMobile) !== 'undefined' && this.isMobilePage()) {
        top -= floaterObject.$element.data(this.dataIdentifierOffsetMobile);
      } else if (floaterObject.$element.data(this.dataIdentifierOffsetDesktop) !== 'undefined' && this.isDesktopPage()) {
        top -= floaterObject.$element.data(this.dataIdentifierOffsetDesktop);
      }

      if (!this.floatStarts[top]) {
        this.floatStarts[top] = [];
      }

      if (!this.floatStops[stop]) {
        this.floatStops[stop] = [];
      }

      this.floatStarts[top].push(floaterObject.$element);
      this.floatStops[stop].push(floaterObject.$element);
    });

    this.lastScrollPosition = 0;
    this.handleScrollFloat();
  }

  /**
     * Run the floater mechanism.
     */
  initFloaters() {
    this.lastScrollPosition = 0;
    this.startFloating = {};
    this.stopFloating = {};
    this.smallerTop = 0;
    this.biggerTop = 0;

    $(window).on('scroll', () => {
      this.handleScrollFloat();
    });
  }

  /**
     * Do the scrolling-float-calculations.
     */
  handleScrollFloat() {
    const scrollTop = parseInt(
      (window.pageYOffset || this.doc.scrollTop) - (this.doc.clientTop || 0),
      10,
    );

    // User is scrolling down.
    if (scrollTop > this.lastScrollPosition) {
      this.startFloating = this.floatStarts;
      this.stopFloating = this.floatStops;
      this.smallerTop = this.lastScrollPosition;
      this.biggerTop = scrollTop;
    }

    // User is scrolling up.
    if (scrollTop < this.lastScrollPosition) {
      this.startFloating = this.floatStops;
      this.stopFloating = this.floatStarts;
      this.smallerTop = scrollTop;
      this.biggerTop = this.lastScrollPosition;
    }

    for (let i = this.smallerTop; i < this.biggerTop; i += 1) {
      if (this.startFloating[i]) {
        this.startFloating[i].forEach((element) => {
          element.addClass(element.data(this.dataIdentifier));
        });
      }

      if (this.stopFloating[i]) {
        this.stopFloating[i].forEach((element) => {
          element.removeClass(element.data(this.dataIdentifier));
        });
      }
    }

    this.lastScrollPosition = scrollTop;
  }

  /**
     * Is the user as an admin on the page?
     *
     * @returns {*|boolean|jQuery}
     */
  isAdminPage() { // eslint-disable-line class-methods-use-this
    return $('body').hasClass('toolbar-fixed') || $('body').hasClass('toolbar-vertical');
  }

  /**
     * Is the user on a page with the mobile navigation?
     *
     * @returns {boolean}
     */
  isMobilePage() { // eslint-disable-line class-methods-use-this
    return $('.page-navs').is(':hidden');
  }

  /**
     * Is the user on a page with the desktop navigation?
     *
     * @returns {*|jQuery}
     */
  isDesktopPage() { // eslint-disable-line class-methods-use-this
    return $('.page-navs').is(':visible');
  }

  /**
     * Initialize function
     */
  init() {
    if (this.floaters.length < 1) {
      return;
    }

    this.setWindowBasedSizes();
    this.initFloaters();
  }

  /**
     * Update stuff on page resize
     */
  updateOnResize() {
    this.setWindowBasedSizes();
  }
}

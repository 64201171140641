import $ from 'jquery';

/**
 * Base class for components
 */
export default class BaseComponent {
  /**
     * Factory method for the component.
     *
     * @param options
     * @return {*}
     */
  static create(options) {
    return (new this(options)).init();
  }

  /**
     * Public constructor
     *
     * @param options
     */
  constructor(options) {
    this.item = options.item || null;
    this.$item = this.item ? $(this.item) : null;
  }

  /**
     * Initialize function
     */
  /* eslint-disable class-methods-use-this */
  init() {
    // Nothing in here.
  }
  /* eslint-enable class-methods-use-this */
}

// Relative imports
import $ from 'jquery';
import SweetScroll from 'sweet-scroll';

// Absolute imports
import BaseComponent from './baseComponent';

/**
 * Toggler for CSS-Classes.
 */
export default class CssClassToggler extends BaseComponent {
  /**
     * Public constructor
     *
     * @param options
     */
  constructor(options) {
    super(options);

    this.identifier = '[data-toggle]';
    this.identifierActive = 'toggleAccordionActive';
    this.identifierActiveStart = 'toggle-accordion-active';

    return this;
  }

  /**
     * Deactivate all elements with cssClass
     * @param cssClass
     */
  deactivateAll(cssClass) {
    $(`.${cssClass}`)
      .removeClass(cssClass)
      .removeData(this.identifierActive);
  }

  /**
     * Get Target of a toggler.
     * Either all of them or - when parent is activated, only targets in the same "space".
     *
     * @param $toggler
     * @returns {*}
     */
  getTarget($toggler) { // eslint-disable-line class-methods-use-this
    if ($toggler.data('toggle-target')) {
      // Check if we look only in the parent divs.
      if ($toggler.data('toggle-target-parent')) {
        return $toggler.closest($toggler.data('toggle-target-parent')).find($toggler.data('toggle-target'));
      }

      // Return target
      return $($toggler.data('toggle-target'));
    }
    return false;
  }

  initClassToggle() {
    const $elements = $(this.identifier);

    $.each($elements, (index, element) => {
      const $element = $(element);

      $element.on('click', (event) => {
        const $clicked = $(event.currentTarget);
        const cssClass = $clicked.data('toggle-class');
        const $target = this.getTarget($clicked);

        // If toggle-accordion: close all the others - if this accordion is close.
        if ($clicked.data('toggle-accordion')) {
          if (typeof ($target.data(this.identifierActive)) === 'undefined') {
            this.deactivateAll(cssClass);
            $target.data(this.identifierActive, true);
          } else {
            $target.removeData(this.identifierActive);
          }

          // Remove data-attribute from start to identify active toggler.
          $(`[data-${this.identifierActiveStart}]`).removeAttr(`data-${this.identifierActiveStart}`);
        }

        // If toggle-self: toggle class on element itself
        if ($clicked.data('toggle-self') !== undefined) {
          $clicked.toggleClass(cssClass);
        }

        // If toggle-target: toggle class on target.
        if ($target) {
          $target.toggleClass(cssClass);
        }
      });
    });
  }

  checkUrlContactFormToggle() { // eslint-disable-line class-methods-use-this
    if (window.location.hash) {
      // Get id by hash
      const id = window.location.hash.substr(1);

      // Get contact-form by id
      const $form = $(`#paragraph-contact-form-${id}`);

      // Open it!
      if ($form.length > 0) {
        const sweetScroll = new SweetScroll({});

        // remove hash
        window.location.hash = '';

        $form.addClass('-open');
        sweetScroll.toElement($form[0], { offset: -240 });
      }
    }
  }

  /**
     * Initialize function
     */
  init() {
    this.initClassToggle();
    this.checkUrlContactFormToggle();

    $(window).bind('hashchange', () => {
      this.checkUrlContactFormToggle();
    });
  }
}

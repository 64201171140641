// import headerForm from '@/js/vfx/headerForm';
import headerHero from '@/js/vfx/headerHero';
import headerImage from '@/js/vfx/headerImage';

const animations = {
  // headerForm,
  headerHero,
  headerImage,
};

export default class Anime {
  init() {
    const $els = document.querySelectorAll('[data-anime]');

    if ($els.length < 1) {
      return this;
    }

    [...$els].forEach(($el) => {
      const { anime: id } = $el.dataset;
      const animation = animations[id];

      if (animation === undefined) {
        throw new Error(`Requested animation with id “${id}” does not exist.`);
      }

      animation.play($el);
    });

    return this;
  }
}

import $ from 'jquery';
import SweetScroll from 'sweet-scroll';
import BaseComponent from './baseComponent';

/**
 * Scroll-To Application
 * http://tsuyoshiwada.github.io/sweet-scroll/
 */
export default class Scroller extends BaseComponent {
  /**
     * Public constructor
     *
     * @param options
     */
  constructor(options) {
    super(options);

    this.elementDataName = options.elementDataName || null;

    return this;
  }

  /**
     * Get Target of a scroller.
     * Either all of them or - when parent is activated, only targets in the same "space".
     *
     * @param $toggler
     * @returns {*}
     */
  getTarget($scroller) { // eslint-disable-line class-methods-use-this
    if ($scroller.data('scroller-target')) {
      // Check if we look only in the parent divs.
      if ($scroller.data('scroller-target-parent')) {
        return $scroller.closest($scroller.data('scroller-target-parent')).find($scroller.data('scroller-target'));
      } if ($scroller.data('scroller-target')) {
        // Check if we look for a specific scroller-target.
        return $($scroller.data('scroller-target'));
      } if ($scroller.data('toggle-target')) {
        // Otherwise, try to return the toggle-target.
        return $($scroller.data('toggle-target'));
      }

      return false;
    }
    return false;
  }

  initScroller() {
    this.$scrollers = $(`[data-${this.elementDataName}]`);
    this.scroller = {};
    const self = this;

    $.each(this.$scrollers, (index, element) => {
      const $scroller = $(element);
      const $target = self.getTarget($scroller);
      let offset = 0;
      let duration = 200;

      if ($scroller.data('scroller-offset')) {
        offset = ($target.position().top - $scroller.position().top) * -1;
        offset -= parseInt($target.css('padding-top'), 10);

        // Remove admin-bar from offset
        offset -= parseInt($('body').css('padding-top'), 10);

        // Remove sticky nav to the offset
        if ($('.page-header')) {
          offset -= parseInt($('.page-header').height(), 10);
        }
      }

      if ($scroller.data('scroller-duration')) {
        duration = $scroller.data('scroller-duration');
      }

      $scroller.addClass(`scroller-identifier-${index}`);
      $scroller.attr('href', `#scroller-target-${index}`);
      $target.attr('id', `scroller-target-${index}`);

      this.scroller[index] = new SweetScroll({
        trigger: `.scroller-identifier-${index}`,
        duration,
        offset,
        cancellable: false,
        header: false,
        updateURL: false,
      });
    });
  }

  scrollToOnStart() { // eslint-disable-line class-methods-use-this
    // Check for Scroll-to-on-start-elements and scroll to it!
    const $scrollTo = $('[data-scroll-to-on-start]');
    const sweetScroll = new SweetScroll({});

    // scroll to the element
    if ($scrollTo.length > 0) {
      sweetScroll.toElement($scrollTo[0], { offset: -80 });
    }
  }

  /**
     * Initialize function
     */
  init() {
    this.initScroller();
    this.scrollToOnStart();
  }

  updateOnResize() {
    this.initScroller();
  }
}

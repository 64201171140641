import $ from 'jquery';
import BaseComponent from './baseComponent';

/**
 * Component for text controllings.
 *
 * - Ellipsis: add mulitline-ellipsis.
 */
export default class TextController extends BaseComponent {
  /**
     * Public constructor
     *
     * @param options
     */
  constructor(options) {
    super(options);

    this.dataIdentifier = 'word-array';
    this.$ellipsis = $('[data-ellipsis]');

    return this;
  }

  addElllipsisToTextbox(element) {
    const $element = $(element);
    const $inner = $element.find('.inner');
    const inner = $inner[0];
    let wordDataArray = [];

    if (inner) {
      if ($element.attr(`data-${this.dataIdentifier}`)) {
        wordDataArray = JSON.parse($element.attr(`data-${this.dataIdentifier}`));
      } else {
        const wordArray = inner.innerHTML.split(' ');
        wordDataArray = wordArray.slice(0);
        $element.attr(`data-${this.dataIdentifier}`, JSON.stringify(wordArray));
      }

      inner.innerHTML = wordDataArray.join(' ');

      while (wordDataArray.length && $inner.height() > element.offsetHeight) {
        wordDataArray.pop();
              inner.innerHTML = `${wordDataArray.join(' ')}...`; // eslint-disable-line
      }
    }
  }

  /**
     * Init Ellipsis for all ellipsis-elements.
     */
  initEllipsis() {
    $.each(this.$ellipsis, (i, e) => {
      this.addElllipsisToTextbox(e);
    });
  }

  /**
     * First initialization.
     */
  init() {
    this.initEllipsis();
  }

  /**
     * Update after window resize.
     */
  updateOnResize() {
    this.initEllipsis();
  }

  /**
     * Update after changing content of DOM.
     */
  updateContent() {
    this.$ellipsis = $('[data-ellipsis]');
    this.initEllipsis();
  }
}

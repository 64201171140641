// import 'babel-polyfill';

// @see http://rangeslider.js.org/
import 'rangeslider.js';

// @see https://github.com/liabru/jquery-match-height
import 'jquery-match-height';

// @see https://jquery.com/
import $ from 'jquery';

// Include object polyfill for videos
// @see https://github.com/constancecchen/object-fit-polyfill
import 'objectFitPolyfill';

// Application components
import AnchorLocationFixer from '@/js/components/anchorLocationFixer';
import CssClassToggler from '@/js/components/cssClassToggler';
import FloatingElement from '@/js/components/floatingElement';
import NavigationToggler from '@/js/components/navigationToggler';
import PollFiller from '@/js/components/pollFiller';
import Scroller from '@/js/components/scroller';
import ScrollToTheTop from '@/js/components/scrollToTheTop';
import SearchContent from '@/js/components/searchContent';
import SvgReplacer from '@/js/components/svgReplacer';
import TextController from '@/js/components/textController';
import Anime from '@/js/components/anime';
import Vivus from '@/js/components/vivus';
import ViewportObserver from '@/js/components/ViewportObserver';
import Header from '@/js/components/header';
import Footer from '@/js/components/footer';

class App {
  constructor() {
    const $html = $('html');
    // Load Events

    const scroller = new Scroller({
      elementDataName: 'scroller',
    });

    scroller.init();

    const cssClassToggler = new CssClassToggler({});
    const floatingElement = new FloatingElement({});
    const pollFiller = new PollFiller({});
    const searchContent = new SearchContent({});
    const textController = new TextController({});
    const anime = new Anime();
    const vivus = new Vivus();
    const viewportObserver = new ViewportObserver();
    const scrollToTheTop = new ScrollToTheTop();

    const svgReplacer = new SvgReplacer({
      svgClass: 'svg-inline',
    });

    cssClassToggler.init();
    floatingElement.init();
    pollFiller.init();
    searchContent.init();
    svgReplacer.init();
    textController.init();
    anime.init();
    vivus.init();
    viewportObserver.init();
    scrollToTheTop.init();

    // Initialize meta navigation
    $('[data-nav-meta]').each((index, item) => {
      const navigationToggler = new NavigationToggler({
        item,
        handlerSelector: '[data-handler]',
        handlerTargetSelector: '> [data-target]',
      });

      navigationToggler.init();
    });

    // Initialize main navigation
    const $pageHeader = $('[data-page-header]');
    $('[data-nav-main]').each((index, item) => {
      const navigationToggler = new NavigationToggler({
        item,
        handlerSelector: '[data-handler]',
        handlerTargetSelector: '+ [data-target]',
        onTargetShow: () => {
          $pageHeader.addClass('open');
        },
        onTargetHideAll: () => {
          $pageHeader.removeClass('open');
        },
      });

      navigationToggler.init();
    });

    // Handle country-switch submit
    $('[data-country-switch]').on('submit', (e) => {
      e.preventDefault();
      const $form = $(e.currentTarget);
      const url = $form.find($form.attr('data-country-switch-selector')).val();
      if (url && url.length > 1) document.location = url;
    });

    // POR-987 Offset anchor locations by header height
    const anchorLocationFixer = new AnchorLocationFixer({
      offsetValue: -$pageHeader.outerHeight(),
    });

    // Initialize anchor fixer on load, this should work for most browsers
    $(window).on('load', () => {
      // For IE, we have to perform some shitty magic since they seem to be having
      // issues reporting values for window.pageYOffset other than 0 on page load
      // @see https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8605539/
      // @see https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/9453934/
      if ($html.hasClass('no-objectfit')) {
        setTimeout(() => {
          anchorLocationFixer.init();
        }, 200);
      } else {
        anchorLocationFixer.init();
      }
    });

    // Fix anchor links on hash change as well
    $(window).on('hashchange', () => {
      anchorLocationFixer.init();
    });

    const language = $('html')[0].lang.split('-')[0];
    $.ajax({
      url: `/portal/public/language/${language}`,
      type: 'GET',
    });
  }
}

$(() => {
  window.App = new App();
  // identify an element to observe
  const elementToObserve = document.querySelector('wc-include');

  const header = new Header();
  const footer = new Footer();

  // create a new instance of `MutationObserver` named `observer`,
  // passing it a callback function

  let initialized = false;
  const observer = new MutationObserver(() => {
    if (initialized) {
      return;
    }
    initialized = true;
    header.init();
    footer.init();
  });

  // call `observe()` on that MutationObserver instance,
  // passing it the element to observe, and the options object
  observer.observe(elementToObserve, { subtree: false, childList: true });
});
